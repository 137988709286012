import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useSetRecoilState } from 'recoil';
import { authState, myState, userPermissionsState, vendorState } from 'recoil/atoms';
import { getMe } from 'api/extra';
import LogRocket from 'logrocket';
import { Storage } from 'shared/storage';
import { Auth0User } from 'types/global';
import { ENV, USERFLOW_TOKEN } from 'shared/constants';
import { useScopes } from 'hooks/useScopes';
import Bugsnag from '@bugsnag/js';
import { useLocation, useNavigate } from 'react-router-dom';
import userflow from 'userflow.js';
import moment from 'moment/moment';
import packageInfo from '../../package.json';

if (ENV === 'production') {
  LogRocket.init('npi-digital/portofino');
}

export const useAuth = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated, user, getAccessTokenSilently, logout, loginWithRedirect } = useAuth0<Auth0User>();
  const setAuth = useSetRecoilState(authState);
  const setVendorState = useSetRecoilState(vendorState);
  const setUserPermissions = useSetRecoilState(userPermissionsState);
  const setMyState = useSetRecoilState(myState);
  const { setScopes } = useScopes();

  /**
   * Check to see if user is logged in.  If not, send them to Auth0 login.
   */
  useEffect(() => {
    (async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        Storage.setToken(accessToken);

        await initializeApp();
      } catch (e) {
        await loginWithRedirect({
          appState: { returnTo: location.pathname }
        });
        sessionStorage.setItem('returnTo', location.pathname + location.search);
        return null;
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccessTokenSilently, loginWithRedirect]);

  const initializeApp = async () => {
    //  Get logged in user info
    getMe().then(async (user) => {
      if (user) {
        /**
         * Determines the permissions for a user.
         *
         * @param {Array} user.permissions - The permissions of the user.
         * @returns {Array} - The permissions of the user, or an empty array if user.permissions is not an array.
         */
        const permissions = Array.isArray(user.permissions) ? user.permissions : [];
        setUserPermissions(permissions);
        setScopes(permissions);
        setMyState(user);
        Storage.set('permissions', JSON.stringify(permissions));

        const npiPersonnel = permissions.indexOf('read:smartbench_access') > -1;
        const vantagePro = permissions.indexOf('read:portofino_full') > -1;
        const vantageBasic = permissions.indexOf('read:portofino_full') === -1;
        const supplierDynamicsAccess = permissions.indexOf('read:supplier_dynamics') > -1;

        // Check if LogRocket has been initialized before calling its methods
        if (ENV === 'production') {
          LogRocket.identify(user.user_id, {
            name: user.name,
            email: user.email,
            client_id: user.client_id,
            client_name: user.client.name,
            portofino: permissions.indexOf('read:portofino') > -1 ? 'true' : 'false',
            version: packageInfo.version,
            npiPersonnel,
            portofinoGold: vantagePro,
            portofinoBasic: vantageBasic,
            vantagePro: vantagePro,
            vantageBasic: vantageBasic,
            supplierDynamicsAccess
          });
        }
        if (sessionStorage.getItem('returnTo')) {
          navigate(sessionStorage.getItem('returnTo') || '/');
          sessionStorage.removeItem('returnTo');
        }

        /**
         * Initialize Userflow wrapper and set user identity.
         * Also add a security layer to our Userflow.js snippet, by requiring a special signature,
         * which only our API computes via a secret key provided by Userflow.
         * This prevents malicious third parties from posting data to Userflow
         * on behalf of our users.
         */
        if (USERFLOW_TOKEN) {
          userflow.init(USERFLOW_TOKEN);
          userflow.setResourceCenterLauncherHidden(true);
          await userflow.identify(
            user.user_id,
            {
              name: user.name,
              email: user.email,
              signed_up_at: moment(user.created_at).toISOString(),
              client: user.client.name,
              last_login: moment(user.last_login).toISOString(),
              npiPersonnel,
              vantagePro: vantagePro,
              vantageBasic: vantageBasic,
              supplierDynamicsAccess: supplierDynamicsAccess
            },
            { signature: user.userflowSignature }
          );
        }

        // Set user and company info for better reporting in Bugsnag
        Bugsnag.setUser(user.user_id, user.email, user.name);
        Bugsnag.addMetadata('company', {
          name: user.client?.name,
          id: user.client?.id
        });

        /**
         * Identify username and email in Freshdesk ticketForm
         */
        if (typeof window.FreshworksWidget === 'function') {
          window.FreshworksWidget('identify', 'ticketForm', {
            name: user.name,
            email: user.email
          });
        }
      }
    });

    // Get all my vendors
    //TODO: temporarily disabled
    // getMyVendors().then((vendors) => {
    //   if (Array.isArray(vendors)) setVendorState({ vendors, selected: undefined });
    // });
  };

  useEffect(() => {
    if (user) setAuth({ user });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleLogout = async () => {
    Storage.remove('lastLocation');
    Storage.remove('permissions');
    setVendorState({ vendors: [], selected: undefined });
    await logout();
  };

  return { isAuthenticated, user, logout: handleLogout };
};
