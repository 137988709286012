import { CheckOutlined, DeleteFilled, MailOutlined, StopOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Popover, Table, TableColumnsType, Tag, Tooltip } from 'antd';
import moment from 'moment';
import React, { useMemo } from 'react';

import { UserTableProps } from './UserManagementTable.types';
import { FieldSorter } from 'sb/shared/Helpers';
import UserEdit from 'assets/UserEdit.svg';
import { Auth0User } from 'types/global';
import { firstLetters } from 'shared/Helpers';
import { DEFAULT_TABLE_PAGINATION_CONFIG } from 'sb/shared/constants';

export const UserManagementTable: React.FC<UserTableProps> = ({
  editable,
  deletable,
  onSendResetEmail,
  onEditUser,
  onDeleteUser,
  ...rest
}) => {
  const columns: TableColumnsType<Auth0User> = useMemo(
    () => [
      {
        title: 'Name',
        dataIndex: 'full_name',
        className: 'text-blue-500',
        sortDirections: ['ascend', 'descend'],
        sorter: (a, b) => FieldSorter(a.full_name, b.full_name)
      },
      {
        title: 'Title',
        dataIndex: 'title',
        sortDirections: ['ascend', 'descend'],
        sorter: (a, b) => FieldSorter(a.title, b.title)
      },
      {
        title: 'Email',
        dataIndex: 'email',
        className: 'text-blue-500'
      },
      {
        title: 'Roles',
        dataIndex: 'user_role',
        key: 'role',
        width: 250,
        render: (val) => {
          if (val?.length > 0) {
            return val?.map((item: any, idx: any) => (
              <Popover
                key={item.role?.name + idx.toString() + 'tooltip'}
                content={<p className="w-96">{item?.role?.description}</p>}
                title={<span className="text-base font-semibold">{item?.role?.name}</span>}
              >
                <Tag key={item.role?.name + idx.toString()} color="#d9d9d9" className="ml-2 leading-tight text-black">
                  {firstLetters(item?.role?.name)}
                </Tag>
              </Popover>
            ));
          }
          return '--';
        }
      },
      {
        title: 'Active',
        key: 'blocked',
        width: 70,
        align: 'center',
        sorter: (a, b) => FieldSorter(a?.blocked?.toString(), b?.blocked?.toString()),
        render: (_, rec) =>
          rec.blocked ? (
            <StopOutlined className="text-gray-300 text-xl font-bold" />
          ) : (
            <CheckOutlined className="text-green-500 text-xl font-bold" />
          )
      },
      {
        title: 'Last Login',
        key: 'last_login',
        sortDirections: ['descend', 'ascend'],
        sorter: (a, b) => FieldSorter(a.last_login, b.last_login),
        render: (_, rec) => {
          return rec.logins_count > 0 && rec?.last_login ? moment(rec.last_login).format('ll') : '-';
        }
      },
      {
        title: 'Logins',
        width: 90,
        dataIndex: 'logins_count',
        sortDirections: ['descend', 'ascend'],
        sorter: (a, b) => a.logins_count - b.logins_count
      },
      {
        title: 'Actions',
        key: 'actions',
        width: 105,
        render: (_, rec) => (
          <div className="flex space-between">
            <Tooltip title="Send Reset Password email">
              <Popconfirm
                title={
                  <>
                    <h3>Reset Password</h3>
                    <hr />
                    <p>
                      Send a Reset Password email to this user? <br /> An email will be sent to{' '}
                      <span className="font-semibold">{rec?.email}</span>
                    </p>
                  </>
                }
                okText="Send Email"
                placement="topLeft"
                onConfirm={() => onSendResetEmail?.(rec)}
                icon={''}
              >
                <Button type="link" size="large" icon={<MailOutlined />} disabled={!editable} />
              </Popconfirm>
            </Tooltip>
            <Tooltip title="Update user record">
              <Button
                className="mt-2"
                type="link"
                size="small"
                icon={<img src={UserEdit} alt="Icon" height={16} />}
                disabled={!editable}
                onClick={() => onEditUser?.(rec)}
              />
            </Tooltip>
            {deletable && (
              <Tooltip title="Delete User">
                <Popconfirm
                  title={
                    <div className="w-[15rem]">Deleting a user is irrevokable. Are you sure you wish to proceed?</div>
                  }
                  placement="topRight"
                  onConfirm={() => onDeleteUser?.(rec)}
                >
                  <Button type="link" size="large" icon={<DeleteFilled className="text-red-500" />} />
                </Popconfirm>
              </Tooltip>
            )}
          </div>
        )
      }
    ],
    [editable, deletable, onSendResetEmail, onEditUser, onDeleteUser]
  );

  return (
    <Table<Auth0User>
      rowKey="auth0_user_id"
      pagination={DEFAULT_TABLE_PAGINATION_CONFIG}
      {...rest}
      size="small"
      columns={columns}
    />
  );
};
