import { axios } from 'sb/shared/axiosClient';
import { AxiosErrorHandler } from 'sb/shared/Helpers';
import { AuthUser, Client } from '../../models/User';

export const searchAuth0Users = async (name: string) => {
  try {
    const res = await axios.get(`/secure/admin/users`, {
      params: { name }
    });
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    return Promise.reject(Error(res.data?.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
};

export const getClientUsers = async (profile_id?: number): Promise<AuthUser[] | undefined> => {
  try {
    const res = await axios.get(`/v1/client/users`, {
      params: { profile_id }
    });
    if (res.data?.status === 'success') {
      return res.data?.data;
    }
    return Promise.reject(Error(res.data?.message));
  } catch (e) {
    AxiosErrorHandler(e);
  }
};
